export class Customer {
  constructor(objInit) {
    this.id = objInit.id;
    this.addedDate = objInit.addedDate;
    this.contactLevels = objInit.contactLevels || [];
    this.contactName = objInit.contactName;
    this.customFields = objInit.customFields || {};
    this.displayId = objInit.displayId;
    this.displayName = objInit.displayName;
    this.modifiedAt = objInit.modifiedAt;
    this.phone = objInit.phone;
    this.risk = objInit.risk;
    this.stats = objInit.stats || {};
    this.totalLocalBalance = objInit.totalLocalBalance;
    this.userCustomerRelations = objInit.userCustomerRelations || {};
    this.workflowEnabled = objInit.workflowEnabled;
    this.workflowId = objInit.workflowId;
    this.flag = objInit.flag;
    this.tags = objInit.tags;
    this._id = objInit._id;
    this.lastNote = objInit.lastNote;

    Object.assign(this, objInit);
  }
}
