import Vue from 'vue';
import httpClient from './httpClient';

const createService = (path, method = 'post', options = { companyId: true }) => {
  return async (payload = {}) => {
    const {
      selectedCompany: { id: companyId },
      settings: { language },
    } = Vue.prototype.$auth.user();
    const enrichedPayload = {
      ...payload,
      user: {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        language,
      },
    };

    if (options.companyId) {
      enrichedPayload.companyId = companyId;
    }

    return httpClient[method.toLowerCase()](path, enrichedPayload);
  };
};

export default createService;
