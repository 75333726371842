import { isEmpty, isEqual } from 'lodash-es';
import { getExtension } from 'mime';
import JSZip from 'jszip';
import Vue from 'vue';

import { invoiceService } from '@services';
import { i18n } from '@plugins';

export default {
  fetchInvoices: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      const { useList, ...rest } = payload;

      const { list, stats } = useList ? await invoiceService.list(rest) : await invoiceService.getTable(rest);

      commit('SET_INVOICES', list);
      commit('SET_STATS', stats);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchInvoicesHistory: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      const { list } = await invoiceService.list(payload);

      commit('SET_INVOICES_HISTORY', list);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateInvoices({ commit }, { payload = {}, submitId }) {
    try {
      commit('SET_IS_LOADING', true);

      this._vm.$VueAnalytics.track({
        eventName: 'edit_response',
        event_uuid: '3f62ea1c-4f8e-4e5f-b068-d6cbc903a485',
        type: 'side_panel',
        action: 'response',
        category: 'edit',
        status: 'success',
        submit_id: submitId,
      });

      await invoiceService.update(payload);
    } catch ({ message }) {
      console.error(message);

      this._vm.$VueAnalytics.track({
        eventName: 'edit_response',
        event_uuid: 'c18c0281-0576-4b50-a7b0-7fd21241ca06',
        type: 'side_panel',
        action: 'response',
        category: 'edit',
        status: 'fail',
        error_message: message,
        submit_id: submitId,
      });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async updateManyInvoices({ commit }, payload) {
    try {
      commit('SET_IS_LOADING', true);

      await invoiceService.updateMany(payload);
    } catch ({ message }) {
      if (isEqual(message, 'mismatch permissions')) {
        throw new Error(message);
      } else {
        console.error(message);
      }
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async deleteInvoice({ commit }, { payload = {}, submitId }) {
    try {
      commit('SET_IS_LOADING', true);

      await invoiceService.delete({ companyId: Vue.auth.user()?.selectedCompany?.id, ...payload });

      this._vm.$VueAnalytics.track({
        eventName: 'delete_response',
        event_uuid: 'd6423bfc-6ec6-4d92-9863-4495f2835108',
        type: 'modal',
        action: 'response',
        category: 'delete',
        status: 'success',
        submit_id: submitId,
        boolean_field_name: 'multiple_selected',
        boolean_field_value: false,
      });
    } catch ({ message }) {
      console.error(message);

      this._vm.$VueAnalytics.track({
        eventName: 'delete_response',
        event_uuid: '03282fe8-df09-44d9-a434-8f4a73d2ced7',
        type: 'modal',
        action: 'response',
        category: 'delete',
        status: 'fail',
        error_message: message,
        submit_id: submitId,
        boolean_field_name: 'multiple_selected',
        boolean_field_value: false,
      });
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchTotalARBalance: async ({ commit }, payload) => {
    try {
      const res = await invoiceService.fetchTotalLocalBalance(payload);

      commit('SET_TOTAL_AR_BALANCE', res);
    } catch ({ message }) {
      console.error(message);
    }
  },
  downloadReport: async (_, payload) => {
    try {
      return await invoiceService.list(payload);
    } catch (error) {
      console.error(error);
    }
  },
  fetchInvoice: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      const { list } = await invoiceService.list(payload);

      return list;
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchInvoicesStatuses: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      return await invoiceService.fetchStatuses(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchInvoicesCurrencies: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);

      return await invoiceService.fetchCurrencies(payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  fetchNonExistingInvoiceIds: async (_, payload) => {
    try {
      return await invoiceService.getNonExistingIds(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  setSelected: ({ commit }, payload) => {
    commit('SET_SELECTED', payload);
  },
  invoicesBulkDownload: async (_, { companyId, invoiceIds }) => {
    try {
      if (invoiceIds.length < 2) {
        const attachmentLink = await invoiceService.fetchAttachmentLink({
          companyId,
          invoicesIds: [invoiceIds[0]],
        });

        const link = document.createElement('a');

        if (!isEmpty(attachmentLink)) {
          link.href = attachmentLink[0].url;
          link.click();
          link.remove();
        }
      } else {
        const zip = new JSZip();

        const links = await invoiceService.fetchAttachmentLink({
          companyId,
          invoicesIds: invoiceIds,
        });

        for await (const attachment of links) {
          const fileExtension = getExtension(attachment.meta.contentType);
          const fileName = `${attachment.invoiceId}.${fileExtension}`;

          const res = await fetch(attachment.url, { method: 'GET' });
          const file = await res.blob();

          zip.file(fileName, file);
        }

        zip
          .generateAsync({
            type: 'blob',
            compression: 'DEFLATE',
          })
          .then((content) => {
            const link = document.createElement('a');
            const blob = new Blob([content], {
              type: 'application/zip',
            });

            link.href = window.URL.createObjectURL(blob);
            link.download = `${i18n.t('invoice.invoices')}.zip`;
            link.click();
            link.remove();

            window.URL.revokeObjectURL(link.href);
          });
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  enableInvoiceWorkflow: async ({ commit }, payload) => {
    try {
      commit('SET_IS_LOADING', true);
      await invoiceService.enableWorkflow(payload);

      commit('UPDATE_INVOICE_WORKFLOW_ENABLED', payload);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  invoicesAttachmentList: async (_, payload) => invoiceService.fetchAttachmentList(payload),
  fetchInvoicePreview: async (_, { companyId, invoicesIds }) => {
    try {
      const attachmentLink = await invoiceService.fetchAttachmentLink({
        companyId,
        invoicesIds,
      });
      return Array.isArray(attachmentLink) ? attachmentLink[0] : attachmentLink;
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchInvoiceProofPreview: async (_, { companyId, bucket, path }) => {
    try {
      return await invoiceService.fetchAttachmentProofLink({
        companyId,
        bucket,
        path,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchInvoicesIds: async ({ commit }, { companyId, skip, limit, search, sortBy, sort }) => {
    try {
      commit('SET_IS_LOADING', true);

      const { invoices, total } = await invoiceService.fetchIds({ companyId, skip, limit, search, sortBy, sort });

      return { invoices, total };
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};
