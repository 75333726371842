import { httpClient, createService } from '@api';

export default {
  list: async (payload) => httpClient.post('invoices/list', payload),
  getTable: createService('invoices/get-table', 'post'),
  update: async (payload) => httpClient.post('invoices/update', payload),
  updateMany: async (payload) => httpClient.post('invoices/update-many', payload),
  delete: async (payload) => httpClient.post('invoices/delete', payload),
  fetchTotalLocalBalance: async (payload) => httpClient.post('invoices/total-local-balance', payload),
  fetchStatuses: async (payload) => httpClient.post('invoices/statuses', payload),
  fetchCurrencies: async (payload) => httpClient.post('invoices/currencies', payload),
  fetchAttachmentLink: async (payload) => httpClient.post('invoices/attachment/link', payload),
  fetchPaymentGatewayChart: async (payload) => httpClient.post('transaction/trend', payload),
  fetchAttachmentList: async (payload) => httpClient.post('invoices/attachment/list', payload),
  enableWorkflow: async (payload) => httpClient.post('invoices/workflow/enabled', payload),
  fetchCountClosed: async (payload) => httpClient.post('invoices/count-closed', payload),
  fetchCountOutstanding: async (payload) => httpClient.post('invoices/count-outstanding', payload),
  fetchCountOverdue: async (payload) => httpClient.post('invoices/count-overdue', payload),
  fetchUpcomingOverdueAging: async (payload) => httpClient.post('invoices/upcoming-overdue-aging', payload),
  fetchAging: async (payload) => httpClient.post('invoices/aging', payload),
  fetchTermsOfPayment: async (payload) => httpClient.post('invoices/terms-of-payments', payload),
  fetchChart: async (payload) => httpClient.post('invoices/chart', payload),
  fetchChartProjected: async (payload) => httpClient.post('invoices/chart/projected', payload),
  createDemoClosed: async (payload) => httpClient.post('invoices/demo/closed-invoices/generate', payload),
  createDemoPaid: async (payload) => httpClient.post('invoices/demo/paid-invoices/generate', payload),
  fetchAttachmentProofLink: async (payload) => httpClient.post('invoices/payment-proof/attachment/link', payload),
  fetchIds: async (payload) => httpClient.post('invoices/ids', payload),
  fetchPaymentsHistoryChart: async (payload) => httpClient.post('invoices/payments-history', payload),
  fetchCollectionRateCompareChart: async (payload) => httpClient.post('invoices/collection-rate', payload),
  getNonExistingIds: async (payload) => httpClient.post('invoices/non-existing-ids', payload),
};
