import { httpClient } from '@api';

import { Record } from './settings.dto';

export default {
  async jobs(payload) {
    try {
      const { records, total } = await httpClient.post('job/find', payload);
      const normalizedList = records.map((item) => new Record(item));

      return { records: normalizedList, total };
    } catch (err) {
      console.warn(err);
    }
  },
  async allFolderFileLinks(payload) {
    try {
      const links = await httpClient.post('job/folder-links', payload);

      return links || [];
    } catch (err) {
      console.warn(err);
    }
  },
  importCustomFields: async (payload) => {
    try {
      await httpClient.post('company/custom-fields/import', payload);
    } catch (error) {
      console.error(error);
    }
  },
  updateCustomField: async (payload) => {
    try {
      await httpClient.post('company/custom-fields/update', payload);
    } catch (error) {
      console.error(error);
    }
  },
  importInternalPersonas: async (payload) => {
    try {
      await httpClient.post('company/user-customer-relations/import', payload);
    } catch (error) {
      console.error(error);
    }
  },
  updateInternalPersonas: async (payload) => {
    try {
      await httpClient.post('company/user-customer-relations/update', payload);
    } catch (error) {
      console.error(error);

      throw new Error(error);
    }
  },
  createPublicAPIKey: async (payload) => {
    try {
      return await httpClient.post('api-keys/create-one', payload);
    } catch (error) {
      console.error(error);
    }
  },
  deletePublicAPIKey: async (payload) => {
    try {
      return await httpClient.post('api-keys/delete-one', payload);
    } catch (error) {
      throw new Error(error);
    }
  },
  readPublicAPIKey: async (payload) => {
    try {
      return await httpClient.post('api-keys/read-many', payload);
    } catch (error) {
      console.error(error);
    }
  },
};
